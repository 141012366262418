import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Hr, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Brewed Awakenings!
			</title>
			<meta name={"description"} content={"Ваша ідеальна чашка кави, створена з турботою та творчістю"} />
			<meta property={"og:title"} content={"Brewed Awakenings!"} />
			<meta property={"og:description"} content={"Ваша ідеальна чашка кави, створена з турботою та творчістю"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6666deb28e2e8e00217cb82b/images/2-4.jpg?v=2024-06-10T13:00:22.091Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6666deb28e2e8e00217cb82b/images/2-4.jpg?v=2024-06-10T13:00:22.091Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6666deb28e2e8e00217cb82b/images/2-4.jpg?v=2024-06-10T13:00:22.091Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6666deb28e2e8e00217cb82b/images/2-4.jpg?v=2024-06-10T13:00:22.091Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6666deb28e2e8e00217cb82b/images/2-4.jpg?v=2024-06-10T13:00:22.091Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6666deb28e2e8e00217cb82b/images/2-4.jpg?v=2024-06-10T13:00:22.091Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6666deb28e2e8e00217cb82b/images/2-4.jpg?v=2024-06-10T13:00:22.091Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="link1">
				МЕНЮ
			</Override>
		</Components.Header>
		<Section padding="60px 0 60px 0" md-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				align-self="center"
			>
				<Text
					font="--headline2"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					Наші пропозиції
				</Text>
				<Text
					color="--light"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					Ласкаво просимо до меню Brewed Awakenings, де кожен пункт є свідченням нашої відданості досконалості кави тощо. Ми підібрали вибір, який сподобається як поціновувачам, так і звичайним любителям кави, гарантуючи, що кожен візит принесе новий і захоплюючий смак.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/6666deb28e2e8e00217cb82b/images/2-4.jpg?v=2024-06-10T13:00:22.091Z"
					width="100%"
					max-height="100%"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					srcSet="https://smartuploads.quarkly.io/6666deb28e2e8e00217cb82b/images/2-4.jpg?v=2024-06-10T13%3A00%3A22.091Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666deb28e2e8e00217cb82b/images/2-4.jpg?v=2024-06-10T13%3A00%3A22.091Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666deb28e2e8e00217cb82b/images/2-4.jpg?v=2024-06-10T13%3A00%3A22.091Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666deb28e2e8e00217cb82b/images/2-4.jpg?v=2024-06-10T13%3A00%3A22.091Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666deb28e2e8e00217cb82b/images/2-4.jpg?v=2024-06-10T13%3A00%3A22.091Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666deb28e2e8e00217cb82b/images/2-4.jpg?v=2024-06-10T13%3A00%3A22.091Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666deb28e2e8e00217cb82b/images/2-4.jpg?v=2024-06-10T13%3A00%3A22.091Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" margin="0px 0px 0px 0px" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" margin="0px auto 0px auto" />
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
			>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Меню
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							- Класична кава – наш традиційний вибір включає свіжозварені варіанти, як-от американо, латте та капучіно, кожен з яких виготовлено з точністю.
							<br />
							<br />
							- Еспресо: Сміливий і насичений, з оксамитовою піною.
							<br />
							<br />
							- Латте: Гладкий і вершковий, ідеальний баланс еспресо та пареного молока.
							<br />
							<br />
							- Капучіно: чудова піна з рівною сумішшю еспресо, парного молока та піни.
							<br />
							<br />
							- Specialty Brews – Спробуйте наші унікальні суміші та сорти кави одного походження, кожна з яких розповідає історію своєї спадщини.
							<br />
							<br />
							- Африканський захід сонця: Яскрава суміш із нотами цитрусових і ягід, отримана з високогір’я Ефіопії.
							<br />
							<br />
							- Sumatra Night: Глибокий і землистий, ідеальний для тих, хто любить ситну насичену каву.
							<br />
							<br />
							- Winter Spice Latte: з корицею та мускатним горіхом ідеально підходить для затишного зимового дня.
							<br />
							<br />
							- Кава з льодом Summer Breeze: злегка підсолоджена та подається охолодженою, освіжаюча літня смакота.
							<br />
							<br />
							- Chamomile Calm: Заспокійливий трав'яний настій, ідеальний для розслаблення.
							<br />
							<br />
							- Магія матча: Багатий антиоксидантами, яскравий зелений матча забезпечує плавний заряд енергії.
							<br />
							<br />
							- Легкі шматочки – доповніть свій напій нашою випічкою та бутербродами, свіжими щодня.
							<br />
							{" "}
							<br />
							- Масляний круасан: пластівчастий і масляний, класичний компаньйон до будь-якої кави.{"\n"}
							<br />
							<br />
							- Тост з авокадо:  свіжоприготований зі стиглим авокадо на кустарному хлібі.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 0px 0px" />
			</Box>
		</Section>
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/6666deb28e2e8e00217cb82b/images/1-3.jpg?v=2024-06-10T13:00:22.096Z) center center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Це лише короткий огляд того, що може запропонувати Brewed Awakenings.
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Наше меню розроблено на будь-який смак, включаючи варіанти для тих, хто має дієтичні переваги чи обмеження. Ми заохочуємо вас зв’язатися з нами, щоб отримати повний огляд наших пропозицій, або, що ще краще, відвідайте нас і дозвольте своїм смаковим рецепторам судити.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Контакти
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});